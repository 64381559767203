<template>
  <div>
    <dashboard-navbar></dashboard-navbar>

    <div class="main-content">
      <h4 class="blue-text font-weight-bold">プロフィール編集</h4>
      <div class="col-12 col-md-6 col-lg-6 col-xl-6"></div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
// Import Custom Components
import dashboardNavbar from '../../components/admin/Dashboard-Navbar.vue';
import mainFooter from '../../components/customer/Main-Footer.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Dashboard',
    };
  },

  components: {
    'dashboard-navbar': dashboardNavbar,
    'main-footer': mainFooter,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped>
/* Init Style */
.main-content {
  padding: 110px 60px 150px 350px;
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 120px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 120px 10px 80px 10px;
  }
}
</style>
